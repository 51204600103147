<template>
  <div class="pd-20">
    <a-page-header style="padding: 0">
      <div slot="title">
        <h1>Relatório de Pagamentos (Boletos)</h1>
      </div>
      <div slot="extra">
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="contractList"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img class="c-pointer" src="@/assets/images/dashboard/excel.png" alt="excel" />
        </downloadExcel>
      </div>
    </a-page-header>

    <a-row v-if="$root.isAdmin()" class="haya-panels mb-20" :gutter="20">
      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total">R$ {{ reports.total }}</div>
          <div class="txt"></div>
          <div class="footer">Total Boletos</div>
        </div>
      </a-col>

      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total">R$ {{ reports.totalPaid }}</div>
          <div class="txt"></div>
          <div class="footer">Total Pago</div>
        </div>
      </a-col>

      <a-col :span="4">
        <div class="box">
          <div class="title"></div>
          <div class="total">R$ {{ reports.totalToReceive }}</div>
          <div class="txt"></div>
          <div class="footer">Total à receber</div>
        </div>
      </a-col>
    </a-row>

    <a-collapse
      class="travel-filters expandable mt-20 mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-0 mb-20" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID</label>
              <a-input
                placeholder="Insira o ID"
                v-model="filters.id"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Contratante</label>
              <a-input
                placeholder="Escreva o primeiro nome"
                v-model="filters.searchTerm"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="4">
            <div class="travel-input">
              <label class="filled">Categorias do Produto</label>
              <a-select
                class="travel-input"
                placeholder="Selecione uma categoria"
                mode="multiple"
                v-model="filters.productsCategory.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.productsCategory.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="5">
            <div class="travel-input">
              <label class="filled">Embarque</label>
              <a-range-picker
                v-model="filters.embark.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col :span="5">
            <div class="travel-input">
              <label class="filled">Vencimentos</label>
              <a-range-picker
                v-model="filters.expiration.selected"
                :format="['DD/MM/YYYY']"
                :value-format="'YYYY-MM-DD'"
                @change="getContracts()"
              />
            </div>
          </a-col>

          <a-col v-if="$root.isAdmin()" :span="4">
            <div class="travel-input">
              <label class="filled">Usuários</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                mode="multiple"
                v-model="filters.users.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.users.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>

          <a-col :span="6" style="display: none">
            <div class="travel-input">
              <label class="filled">Status</label>
              <a-select
                class="travel-input"
                placeholder="Selecione o status do contrato"
                mode="multiple"
                v-model="filters.status.selected"
                show-search
                style="width: 100%"
                @change="getContracts()"
              >
                <a-select-option
                  v-for="(item, index) of filters.status.list"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="contractList"
      :loading="loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="contractsTableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <a slot="contractor" slot-scope="text">{{ text }}</a>

      <div slot="departureDate" slot-scope="record">
        {{ formatContractDepartureDates(record.theContract.meta) }}
      </div>

      <div
        slot="billingTicketValue"
        slot-scope="record"
        v-html="
          formatPrice(
            record.theContract.meta.payment_methods_billing_ticket_total_value
          )
        "
      ></div>

      <div slot="installments" slot-scope="record">
        {{
          record.theContract.meta.payment_methods_billing_ticket_installments
        }}
      </div>

      <div
        slot="paidBillingTickets"
        slot-scope="record"
        v-html="
          thePaidbillingTickets(record.theContract.meta, record.theContract.id)
            .totalPaidFormated
        "
      ></div>

      <div slot="billingTicketExpiration" slot-scope="record">
        {{ formatBillingTicketExpiration(record.theContract.meta) }}
      </div>

      <template slot="product" slot-scope="record">
        <div :class="record">
          <a-tooltip
            class="travel-tooltip"
            v-for="product in record"
            :key="product"
            placement="top"
          >
            <template slot="title">
              <div v-html="product.name" />
            </template>
            <a-tag :color="product.color">
              <div v-html="product.name" />
            </a-tag>
          </a-tooltip>
        </div>
      </template>
      <div slot="totalValue" slot-scope="record" v-html="record"></div>
      <div slot="payments" slot-scope="record">
        <a-tag
          class="ant-tag-price"
          v-for="(payment, index) in record"
          :key="index"
        >
          <span v-html="payment.name" />
        </a-tag>
      </div>

      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Ver">
            <a class="view ml-15" @click="view(record.id)">
              <a-icon
                slot="prefix"
                :type="record.contract_done ? 'green-eye-svg' : 'eye-svg'"
              />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    >

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="pagination.page"
        :page-size-options="pageSizeOptions"
        :total="pagination.total"
        @change="changePage"
        @showSizeChange="changePageSize"
      >
        <template slot="buildOptionText" slot-scope="props">
          <span v-if="props.value !== '99999'">{{ props.value }}/página</span>
          <span v-if="props.value === '99999'"> Todos</span>
        </template>
      </a-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { format, parse } from "date-fns";
import formatThings from "@/mixins/general/formatThings.js";

export default {
  name: "ContractPaymentsReport",
  mixins: [formatThings],
  data() {
    return {
      pageSizeOptions: ["10", "25", "50", "100", "200", "500", "1000", "99999"],
      reports: {
        total: 0,
        totalPaid: 0,
        totalToReceive: 0,
      },
      openFilters: 1,
      filters: {
        loadingResults: false,
        id: "",
        paymentMethods: {
          list: [
            {
              label: "Cartão de Crédito",
              value: "credit-card",
            },
            {
              label: "Boleto",
              value: "billing-ticket",
            },
            {
              label: "Transferência Bancária",
              value: "bank-transfer",
            },
            {
              label: "Carta de Crédito",
              value: "payment-card",
            },
            {
              label: "Link de Pagamento",
              value: "payment-link",
            },
          ],
          selected: ["billing-ticket"],
        },
        companies: {
          list: [
            {
              label: "Viajar Resorts",
              value: "Viajar Resorts",
            },
            {
              label: "Voe Simples",
              value: "Voe Simples",
            },
            {
              label: "Credimilhas",
              value: "Credimilhas",
            },
          ],
          selected: [],
        },
        personTypes: {
          list: [
            {
              label: "Pessoa Física",
              value: "Pessoa Física",
            },
            {
              label: "Pessoa Jurídica",
              value: "Pessoa Jurídica",
            },
          ],
          selected: [],
        },
        users: {
          list: [],
          selected: [],
        },
        productsCategory: {
          list: [
            {
              label: "Hotéis/Resorts",
              value: "hotel",
            },
            {
              label: "Aéreo",
              value: "flight",
            },
            {
              label: "Serviços",
              value: "service",
            },
          ],
          selected: [],
        },
        products: {
          list: [],
          selected: [],
        },
        clients: {
          list: [],
          selected: [],
        },
        status: {
          list: [
            {
              label: "Concluído",
              value: "concluded",
            },
            {
              label: "Em edição",
              value: "edit",
            },
            {
              label: "Cancelado",
              value: "canceled",
            },
          ],
          selected: ["concluded"],
        },
        embark: {
          selected: [],
        },
        expiration: {
          selected: [],
        },
        period: {
          selected: [],
        },
        searchTerm: "",
        price: {
          min: "",
          max: "",
        },
        order: "desc",
        orderBy: "created",
      },
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
        totalPages: 0,
      },
      cancelContract: {
        id: null,
        modal: false,
        loading: false,
        reason: null,
        contract: {},
      },
      showGlobalActions: false,
      contractList: [],
      totalSold: 0,
      totalContracts: 0,
      idInfotera: null,
      infoteraLoading: false,
      infoteraData: [],
      createdId: null,
      contractData: {},
      disableButton: false,
      form: this.$form.createForm(this),
      showDocuments: false,
      documents: {
        documentsSelector: [],
        documentSelectorIsOpen: false,
        viajarResorts: [
          {
            id: 1,
            name: "Viajar Resorts - Documentos - Padrão",
          },
        ],
        voeSimples: [
          {
            id: 2,
            name: "Voe Simples - Documento - Federal",
          },
        ],
      },
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 70,
          sorter: true,
        },
        {
          title: "Contratante",
          dataIndex: "contractor",
          key: "contractor",
          width: 200,
        },
        {
          title: "Produtos",
          dataIndex: "product",
          key: "product",
          scopedSlots: { customRender: "product" },
          width: 300,
        },
        {
          title: "Data Embarque",
          scopedSlots: { customRender: "departureDate" },
          width: 120,
        },
        {
          title: "Valor Total",
          dataIndex: "totalValue",
          key: "totalValue",
          scopedSlots: { customRender: "totalValue" },
          width: 120,
        },
        {
          title: "Valor Boleto",
          scopedSlots: { customRender: "billingTicketValue" },
          width: 120,
        },
        {
          title: "Parcelas",
          scopedSlots: { customRender: "installments" },
          width: 100,
        },
        {
          title: "Pagos",
          scopedSlots: { customRender: "paidBillingTickets" },
          width: 120,
        },
        {
          title: "Data Último Vencimento Boleto",
          scopedSlots: { customRender: "billingTicketExpiration" },
          width: 120,
        },
        {
          title: "Usuário",
          dataIndex: "user",
          key: "user",
          width: 120,
        },
        {
          title: "Data/Hora",
          dataIndex: "created",
          key: "created",
          sorter: true,
          width: 120,
        },
        {
          title: "",
          key: "action",
          align: "right",
          //width: this.$root.isAdmin() ? 180 : 100,
          fixed: this.$root.isAdmin() ? false : "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      companies: [
        { label: "Viajar Resorts", value: "Viajar Resorts" },
        { label: "Voe Simples", value: "Voe Simples" },
        { label: "Credimilhas", value: "Credimilhas" },
      ],
      defaultCompany: "Viajar Resorts",
      personTypes: [
        { label: "Pessoa Física", value: "Pessoa Física" },
        { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
      ],
      defaultPersonType: "Pessoa Física",
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          selectedRowKeys;
          selectedRows;

          this.showGlobalActions = true;

          if (selectedRows.length == 0) {
            this.showGlobalActions = false;
          }
        },
        onSelect: (record, selected, selectedRows) => {
          record;
          selected;

          this.showGlobalActions = true;

          // console.log(
          //     "onselect",
          //     selectedRows.map((item) => {
          //         return item.id;
          //     })
          // );

          if (selectedRows.length == 0) {
            this.showGlobalActions = false;
          }
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
          selected;
          changeRows;

          this.showGlobalActions = true;

          // console.log(
          //     "onselectalla",
          //     selectedRows.map((item) => {
          //         return item.id;
          //     })
          // );

          if (selectedRows.length == 0) {
            this.showGlobalActions = false;
          }
        },
      },
      loading: false,
      visible: false,
      openUpdateHistory: false,
      theContract: [],
      excelFile: {
        header: `RELATÓRIO DE PAGAMENTOS (BOLETOS)`,
        fileName: `RELATÓRIO DE PAGAMENTOS (BOLETOS).xls`,
        collumns: {
          ID: "id",
          Contrante: "contractor",
          Produtos: "productsExcel",
          "Data Embarque": {
            field: "theContract",
            callback: (contract) => {
              return `${this.formatContractDepartureDates(contract.meta)}`;
            },
          },
          "Valor Total": "totalValue",
          "Valor Boleto": {
            field: "theContract",
            callback: (contract) => {
              return `${this.formatPrice(
                contract.meta.payment_methods_billing_ticket_total_value
              )}`;
            },
          },
          Parcelas: {
            field: "theContract",
            callback: (contract) => {
              return `${contract.meta.payment_methods_billing_ticket_installments}`;
            },
          },
          Pagos: {
            field: "theContract",
            callback: (contract) => {
              return `${
                this.thePaidbillingTickets(contract.meta, 1).totalPaidFormated
              }`;
            },
          },
          "Último Vencimento": {
            field: "theContract",
            callback: (contract) => {
              return `${this.formatBillingTicketExpiration(contract.meta)}`;
            },
          },
          Usuário: "user",
          "Data/Hora": "created",
        },
        data: [],
        footer: [],
      },
    };
  },
  computed: {
    routeTab() {
      return {
        title: `Relatório de Pagamentos (Boletos) - Contratos`,
        icon: "line-chart",
        tips: "",
      };
    },
  },
  beforeMount() {
    this.getUsers();
    this.getContracts();
    if (this.$store.state.createContract) {
      this.visible = true;
      this.getDocuments();
    }
  },
  methods: {
    getDocuments() {
      this.$http
        .get("/document/read")
        .then(({ data }) => {
          let activeDocs = [];
          data.data.forEach((doc) => {
            if (doc.status == 1) {
              activeDocs.push({
                id: doc.id,
                name: `#${doc.id} - ${doc.name}`,
                company: doc.company,
              });
            }
          });

          activeDocs.push({
            id: "10",
            name: `#10 - (SISTEMA) Anexo II - Terceiros `,
            company: "all",
          });

          activeDocs.push({
            id: "9",
            name: `#9 - (SISTEMA) Autorização de Débito`,
            company: "all",
          });

          this.documents.documentsSelector = activeDocs;
        })
        .catch(({ res }) => {
          res;
        });
    },
    theContractor(contract) {
      let name = "NÃO INFORMADO";

      if (contract.customer == false) {
        // if (contract.customer) {
        //     name = `${contract.customer.first_name.toUpperCase()} ${contract.customer.last_name.toUpperCase()}`;
        // } else {
        //     name = `${contract.meta.client_name.toUpperCase()}`;
        // }
        if (contract.meta.client_name != undefined) {
          name = `${contract.meta.client_name.toUpperCase()}`;
        }
      } else {
        if (contract.customer.person_type == "Pessoa Física") {
          name = `${contract.customer.first_name.toUpperCase()} ${contract.customer.last_name.toUpperCase()}`;
        } else {
          name = `${contract.customer.trading_name.toUpperCase()} `;
        }
      }

      return name;
    },
    submitCancelContract() {
      if (this.cancelContract.reason) {
        this.cancelContract.loading = true;
        this.$http
          .post("/contract/update-field", {
            id: this.cancelContract.id,
            field: "status",
            value: "canceled",
          })
          .then(({ data }) => {
            data;
            this.getContracts(true);
            this.closeCancelContractModal();
            //this.getNotifications();
            this.$message.success("Contrato atualizado com sucesso!");
            this.cancelContract.loading = false;
          })
          .catch(({ response }) => {
            this.$message.error(response.data.message);
            this.cancelContract.loading = false;
          });

        this.$http
          .post("/contract/update-meta", {
            id: this.cancelContract.id,
            info_name: "contract_cancelation_reason",
            info_value: this.cancelContract.reason,
            action: "cancel-contract",
            description: `<font class="user">${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}</font> cancelou o contrato  <font class="id">ID ${this.contract.id}</font> pelo motivo: ${this.cancelContract.reason}.`,
            modified_by: {
              name: `${this.$store.state.userData.first_name} ${this.$store.state.userData.last_name}`,
              id: this.$store.state.userData.id,
            },
          })
          .then(({ data }) => {
            data;
          })
          .catch(({ response }) => {
            response;
          });
      } else {
        this.$message.warning("Escreva o motivo do cancelamento do contrato.");
      }
    },
    changePage(current) {
      this.pagination.page = current;
      this.getContracts();
    },
    formatContractDepartureDates(meta) {
      let theDate = "";

      let services = JSON.parse(meta.contracted_services);

      if (services.includes("hotel")) {
        theDate = this.formatMultiDates(meta.hotel_1_checkin);
      }

      if (services.includes("flight")) {
        theDate = this.formatMultiDates(meta.flight_1_section_1_departure_date);
      }

      return theDate;
    },
    formatBillingTicketExpiration(meta) {
      let theDate = "";
      let totalInstallments = meta.payment_methods_billing_ticket_installments;

      theDate = this.formatMultiDates(
        meta[
          `payment_methods_billing_ticket_installment_${totalInstallments}_expiration_date`
        ]
      );

      return theDate;
    },
    thePaidbillingTickets(meta) {
      let totalInstallments = meta.payment_methods_billing_ticket_installments;
      let today = new Date().getTime();

      let totalPaid = 0;

      for (var i = 1; i < parseInt(totalInstallments) + 1; i++) {
        let expirationDate =
          meta[
            `payment_methods_billing_ticket_installment_${i}_expiration_date`
          ];

        expirationDate = Date.parse(expirationDate);

        if (expirationDate < today) {
          totalPaid += parseFloat(
            this.convertNumberToDatabase(
              meta[`payment_methods_billing_ticket_installment_${i}`]
            )
          );
        }
      }

      return {
        total: meta.payment_methods_billing_ticket_total_value,
        totalPaid: totalPaid,
        totalPaidFormated: this.formatPrice(totalPaid),
      };
    },
    changePageSize(current, pageSize) {
      this.pagination.page = current;
      this.pagination.perPage = pageSize;

      this.getContracts();
    },
    contractsTableChange(pagination, filters, sorter) {
      // console.log("sorter s", sorter, pagination, filters);
      this.filters.order = sorter.order != undefined ? sorter.order : "desc";
      this.filters.orderBy =
        sorter.column != undefined ? sorter.column.key : "created";
      this.getContracts();
    },
    getContracts(samePage) {
      this.loading = true;

      let filters = "";
      if (!this.$root.isAdmin()) {
        filters += `&user=${this.$store.state.userData.id}`;
      } else {
        if (this.filters.users.selected.length > 0) {
          filters += `&user=${this.filters.users.selected}`;
        }
      }

      if (this.filters.companies.selected.length > 0) {
        filters += `&company=${this.filters.companies.selected}`;
      }

      if (this.filters.status.selected.length > 0) {
        filters += `&status=${this.filters.status.selected}`;
      }

      if (this.filters.paymentMethods.selected.length > 0) {
        filters += `&payment-methods=${this.filters.paymentMethods.selected}`;
      }

      if (this.filters.personTypes.selected.length > 0) {
        filters += `&person-type=${this.filters.personTypes.selected}`;
      }

      if (this.filters.productsCategory.selected.length > 0) {
        filters += `&product-categories=${this.filters.productsCategory.selected}`;
      }

      // if (this.filters.period.selected.length > 0) {-
      //     filters += `&period=${this.filters.period.selected[0]}|${this.filters.period.selected[1]}`;
      // }

      if (this.filters.embark.selected.length > 0) {
        filters += `&embark=${this.filters.embark.selected[0]}|${this.filters.embark.selected[1]}`;
      }

      if (this.filters.expiration.selected.length > 0) {
        filters += `&billing-expiration=${this.filters.expiration.selected[0]}|${this.filters.expiration.selected[1]}`;
      }

      if (this.filters.price.min || this.filters.price.max) {
        let min = this.filters.price.min;
        let max = this.filters.price.max;

        if (min) {
          if (!max) {
            max = "500000";
          }
        }

        if (max) {
          if (!min) {
            min = "0";
          }
        }

        filters += `&price-range=${min}-${max}`;
      }

      if (this.filters.searchTerm) {
        filters += `&s=${this.filters.searchTerm}`;
      }

      if (this.filters.id) {
        filters += `&id=${this.filters.id}`;
      }

      filters += `&order=${this.filters.order}&order-by=${this.filters.orderBy}`;

      this.$http
        .get(
          `/contract-v2/list?page=${this.pagination.page}&per_page=${this.pagination.perPage}${filters}`
        )
        .then(({ data }) => {
          if (!samePage) {
            this.pagination.page = 1;
          }

          this.pagination.total = data.meta.total;
          this.pagination.totalPages = data.meta.total_pages;

          this.totalSold = data.meta.total_sold;
          this.totalContracts = data.meta.total;

          this.contractList = data.data.map((contract) => {
            return {
              id: contract.id,
              contractor: this.theContractor(contract),
              product:
                this.formatProducts(contract.meta) != "UNDEFINED"
                  ? this.formatProducts(contract.meta)
                  : "NÃO INFORMADO",
              payments:
                this.formatPayments(contract.meta) != "UNDEFINED"
                  ? this.formatPayments(contract.meta)
                  : "NÃO INFORMADO",
              company: contract.company,
              user: `${contract.user.first_name} ${contract.user.last_name}`,
              status: contract.status,
              totalValue: this.formatPrice(
                contract.meta.reservation_total_price
              ),
              changeHistory: contract.update_logs,
              created: this.formatDate(contract.created),
              productsExcel:
                this.formatProductsTxt(contract.meta) != "UNDEFINED"
                  ? this.formatProductsTxt(contract.meta)
                  : "NÃO INFORMADO",
              contract_done:
                contract.meta["contract_done"] != undefined
                  ? `${contract.meta.contract_done}`
                  : false,
              theContract: contract,
            };
          });

          this.reports = {
            total: data.meta.total_billing_ticket,
            totalPaid: data.meta.total_paid_billing_ticket,
            totalToReceive: data.meta.total_to_receive_billing_ticket,
          };

          this.excelFile.footer = [];

          this.excelFile.footer.push(" ");

          this.excelFile.footer.push(
            `TOTAL DE BOLETOS: R$ ${data.meta.total_billing_ticket}`
          );

          this.excelFile.footer.push(
            `TOTAL PAGO: R$ ${data.meta.total_paid_billing_ticket}`
          );

          this.excelFile.footer.push(
            `TOTAL À RECEBER: R$ ${data.meta.total_to_receive_billing_ticket}`
          );

          this.loading = false;
        })
        .catch(({ response }) => {
          response;
          this.contractList = [];
          this.pagination.total = response.data.meta.total;
          this.pagination.totalPages = response.data.meta.total_pages;

          this.totalSold = response.data.meta.total_sold;
          this.totalContracts = response.data.meta.total;

          this.reports = {
            total: response.data.meta.total_billing_ticket,
            totalPaid: response.data.meta.total_paid_billing_ticket,
            totalToReceive: response.data.meta.total_to_receive_billing_ticket,
          };

          this.loading = false;
        });
    },
    formatProductsTxt(meta) {
      let txt = "";

      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          // HOTEL
          if (p == "hotel") {
            JSON.parse(meta.hotel_rows).forEach((row) => {
              if (meta[`hotel_${row.id}_name`] != undefined) {
                txt += meta[`hotel_${row.id}_name`]
                  ? meta[`hotel_${row.id}_name`] + "<br>"
                  : `HOTEL ${row.id} <br>`;
              }
            });
          }

          // AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                if (index == 0) {
                  txt += `AÉREO - ${meta[`flight_${row.id}_class`]} - SAÍDA: ${
                    meta[`flight_${row.id}_origin`]
                  } <br>`;
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    txt += `AÉREO ${flight.id}  ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈ ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br> `;
                  }

                  if (
                    index == flight.sections.length - 1 &&
                    flight.sections.length > 1
                  ) {
                    txt += `AÉREO ${flight.id} ${type} - ${
                      meta[`flight_${flight.id}_section_${section.id}_class`] !=
                      undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_class`
                          ]
                        : "CLASSE"
                    } - ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_origin`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_origin`
                          ]
                        : "ORIGEM"
                    } ✈  ${
                      meta[
                        `flight_${flight.id}_section_${section.id}_destination`
                      ] != undefined
                        ? meta[
                            `flight_${flight.id}_section_${section.id}_destination`
                          ]
                        : "DESTINO"
                    } <br>`;
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            JSON.parse(meta.service_rows).forEach((row) => {
              if (meta[`service_${row.id}_name`] != undefined) {
                txt += meta[`service_${row.id}_name`];
              }
            });
          }
        });
      }

      return txt.toUpperCase();
    },
    getUsers() {
      this.$http
        .get("/user/read")
        .then(({ data }) => {
          this.filters.users.list = data.map((user) => {
            return {
              label: `${user.id} - ${user.first_name} ${user.last_name}`,
              value: user.id,
            };
          });
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    formatPrice(price) {
      let thePrice = price;

      if (price) {
        if (typeof price == "number") {
          //
        } else {
          if (price.includes(",")) {
            thePrice = price.replace(",", ".");
          } else {
            thePrice = price;
          }
        }
      } else {
        thePrice = 0.0;
      }

      thePrice = Number(parseFloat(thePrice).toFixed(2)).toLocaleString(
        "pt-BR",
        {
          style: "currency",
          currency: "BRL",
        }
      );

      return `<span class="ant-tag ant-tag-price"> ${thePrice} </span>`;
    },
    search: _.debounce(function (e) {
      this.loading = true;
      this.$http
        .post("/contract/search?s=" + e.target.value)
        .then(({ data }) => {
          let filteredContracts = [];

          if (!this.$root.isAdmin()) {
            data.data.forEach((contract) => {
              if (
                contract.user.email == this.$store.state.userData.email ||
                contract.meta.client_consultant ==
                  this.$store.state.userData.email
              ) {
                //
                filteredContracts.push(contract);
              }
            });
          } else {
            filteredContracts = data.data;
          }

          this.contractList = filteredContracts.map((contract) => {
            return {
              id: contract.id,
              contractor:
                contract.meta["client_name"] != undefined
                  ? `${contract.meta.client_name.toUpperCase()}`
                  : "NÃO INFORMADO",
              product:
                this.formatProducts(contract.meta) != "UNDEFINED"
                  ? this.formatProducts(contract.meta)
                  : "NÃO INFORMADO",
              payments:
                this.formatPayments(contract.meta) != "UNDEFINED"
                  ? this.formatPayments(contract.meta)
                  : "NÃO INFORMADO",
              totalValue: this.formatPrice(
                contract.meta.reservation_total_price
              ),
              company: contract.company,
              user: `${contract.user.first_name} ${contract.user.last_name} `,
              status: contract.status,
              changeHistory: contract.update_logs,
              created: this.formatDate(contract.created),
              contract_done:
                contract.meta["contract_done"] != undefined
                  ? `${contract.meta.contract_done}`
                  : false,
              theContract: contract,
            };
          });
        })
        .catch(({ response }) => {
          response;
          // this.$message.error(response.data.message);

          this.contractList = [];
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }, 600),
    formatPayments(meta) {
      let allPayments = [];

      if (meta.payment_methods != undefined) {
        JSON.parse(meta.payment_methods).forEach((payment) => {
          if (payment == "credit-card") {
            allPayments.push({
              name: "Cartão de Crédito",
              color: "orange",
            });
          }

          if (payment == "billing-ticket") {
            allPayments.push({
              name: "Boleto",
              color: "purple",
            });
          }

          if (payment == "payment-card") {
            allPayments.push({
              name: "Carta de Pagamento",
              color: "blue",
            });
          }

          if (payment == "custom-payment") {
            allPayments.push({
              name: `Personalizado: <br> <span class="custom-payment"> (${meta.payment_methods_custom_payment_name}) </span>`,
              color: "blue",
            });
          }

          if (payment == "bank-transfer") {
            allPayments.push({
              name: "Transferência Bancária",
              color: "blue",
            });
          }

          if (payment == "payment-link") {
            allPayments.push({
              name: "Link de Pagamento",
              color: "orange",
            });
          }
        });
      }

      return allPayments;
    },
    formatProducts(meta) {
      let allProducts = [];

      if (meta.contracted_services) {
        JSON.parse(meta.contracted_services).forEach((p) => {
          // HOTEL
          if (p == "hotel") {
            JSON.parse(meta.hotel_rows).forEach((row) => {
              if (meta[`hotel_${row.id}_name`] != undefined) {
                allProducts.push({
                  color: "green",
                  name: meta[`hotel_${row.id}_name`]
                    ? meta[`hotel_${row.id}_name`]
                    : `HOTEL ${row.id}`,
                });
              }
            });
          }

          // AÉREO
          if (p == "flight") {
            if (
              meta.flight_rows != undefined &&
              meta.flight_sections_rows == undefined
            ) {
              JSON.parse(meta.flight_rows).forEach((row, index) => {
                row;

                if (index == 0) {
                  allProducts.push({
                    color: "blue",
                    name: `AÉREO - ${meta[`flight_${row.id}_class`]} - SAíDA: ${
                      meta[`flight_${row.id}_origin`]
                    }`,
                  });
                }
              });
            }

            if (meta.flight_sections_rows != undefined) {
              let type = "";
              JSON.parse(meta.flight_sections_rows).forEach((flight) => {
                flight.sections.forEach((section, index) => {
                  index;

                  type = ` (${
                    meta[`flight_${flight.id}_section_${section.id}_type`]
                  }) `;

                  if (type == " (undefined) ") {
                    type = "";
                  }

                  if (index == 0) {
                    allProducts.push({
                      color: "blue",
                      name: `AÉREO ${flight.id}  ${type} - ${
                        meta[`flight_${flight.id}_section_${section.id}_class`]
                      } - ${
                        meta[`flight_${flight.id}_section_${section.id}_origin`]
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ]
                      }`,
                    });
                  }

                  if (index == flight.sections.length - 1) {
                    allProducts.push({
                      color: "blue",
                      name: `AÉREO ${flight.id} ${type} - ${
                        meta[`flight_${flight.id}_section_${section.id}_class`]
                      } - ${
                        meta[`flight_${flight.id}_section_${section.id}_origin`]
                      } <i class="fas fa-plane" style="margin: 0 2px; font-size: 8px; top:-1px; position: relative;"></i>  ${
                        meta[
                          `flight_${flight.id}_section_${section.id}_destination`
                        ]
                      }`,
                    });
                  }
                });
              });
            }
          }

          // SERVIÇOS

          if (p == "service") {
            JSON.parse(meta.service_rows).forEach((row) => {
              if (meta[`service_${row.id}_name`] != undefined) {
                allProducts.push({
                  color: "orange",
                  name: meta[`service_${row.id}_name`],
                });
              }
            });
          }
        });
      }

      return allProducts.length > 0
        ? allProducts
        : [{ color: "", name: "SEM PRODUTOS" }];
    },
    disableCreation() {
      if (this.idInfotera) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$store.state.createContract = false;
      this.form.validateFields((err, values) => {
        // values.infotera = JSON.stringify(this.infoteraData);
        values.id_infotera = this.idInfotera;
        values.user = this.$store.state.userData.id;
        values.status = "new";
        if (!err) {
          this.new({ ...values, ...this.infoteraData });
        }
      });
    },
    closeDocumentsSelector() {
      this.documents.documentSelectorIsOpen = false;
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
    showModal() {
      this.visible = true;
      this.getDocuments();
    },
    handleOk() {
      this.visible = false;
      this.$store.state.createContract = false;
    },
    closeModal() {
      this.visible = false;
      this.$store.state.createContract = false;
      this.showDocuments = false;
    },
    onCompanyChange(company) {
      // console.log(company.target.value, this.documents.documentsSelector);
      this.showDocuments = true;
      this.form.setFieldsValue({
        contract_documents: [],
      });

      let filteredDocs = [];

      this.documents.documentsSelector.forEach((doc) => {
        //  console.log(company.target.value, company);
        if (doc.company == company.target.value || doc.company == "all") {
          filteredDocs.push(doc);
        }
      });

      this.documentsSelector = filteredDocs;
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
    view(id) {
      window.open(`/contracts/view/${id}`, "_blank");
    },
    cancel(contract) {
      this.cancelContract.id = contract.id;
      this.cancelContract.contract = contract;
      this.cancelContract.modal = true;
      this.cancelContract.reason =
        contract.theContract.meta.contract_cancelation_reason != undefined
          ? contract.theContract.meta.contract_cancelation_reason
          : null;
    },
    closeCancelContractModal() {
      this.cancelContract = {
        id: null,
        modal: false,
        loading: false,
        reason: null,
      };
    },
    changeHistory(contract) {
      this.openUpdateHistory = true;
      this.theContract = contract;
      this.theContract.changeHistory = contract.changeHistory;
    },
    onClose() {
      this.openUpdateHistory = false;
    },
    new(contractData) {
      delete contractData["contract_documents"];
      this.$store.state.newContract = contractData;
      this.$store.state.newContract.meta = {};
      this.$store.state.newContract.meta.payment_methods = JSON.stringify([]);
      this.$store.state.newContract.meta.contract_documents = JSON.stringify(
        []
      );
      this.$store.state.newContract.meta = {
        ...this.$store.state.newContract.meta,
        ...this.infoteraData,
      };
      this.$router.push("new");
    },
    create(contractData) {
      this.$http
        .post("/contract/create", contractData)
        .then(({ data }) => {
          this.$router.push(`edit/${data.id}`);
          this.getContracts();
          this.$message.success(data.message);
          this.visible = false;
          this.createdId;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    duplicate(id) {
      this.$http
        .post("/contract/duplicate", {
          id: id,
          user_id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.getContracts();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    confirmDelete(id) {
      this.$http
        .post("/contract/delete", {
          id: id,
        })
        .then(({ data }) => {
          this.$message.success(data.message + "!");
          this.getContracts();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    cancelDelete(e) {
      e;
    },
    filterResults() {
      this.filters.loadingResults = false;
      //   console.log(this.filters);
    },
  },
};
</script>
